import React, { useEffect, useState } from "react";
import { getAllPdfs } from "../../utils";
import { Link } from "react-router-dom";
import {
  Button,
  PDFListRow,
  VideoVelocityTypePopup,
  DeckVelocityTypePopup,
  Pagination,
} from "../index";

const PDFList = ({ toggleShowProcessingOverlay }) => {
  // first fetch, with loader
  const [pdfs, setPdfs] = useState([]);
  const [page, setPage] = useState(
    localStorage.getItem("pdfPage") ? localStorage.getItem("pdfPage") : 1
  );
  const [pageSize, setPageSize] = useState(10);
  const [totalCount, setTotalCount] = useState(0);
  useEffect(() => {
    (async () => {
      const pdfs = await getAllPdfs(true, page, pageSize);
      if(pdfs?.data){
        setPdfs(pdfs?.data);
        setPage(pdfs?.page);
        setTotalCount(pdfs?.total_count);
      }
    })();
  }, []);

  const changePage = async (curpage) => {
    setPage(curpage);
    const pdfs = await getAllPdfs(false, curpage, pageSize);
    if(pdfs?.data){
      setPdfs(pdfs?.data);
      setPage(pdfs?.page);
      setTotalCount(pdfs?.total_count);
      localStorage.setItem("pdfPage", pdfs?.page);
    }
  };

  const toggleShowProcessingOverlayNext = (data) => {
    toggleShowProcessingOverlay(data);
  };

  // polling without loader
  useEffect(() => {
    const myInterval = setInterval(fetchQuote, 10000);

    return () => {
      clearInterval(myInterval);
    };
  }, []);

  const fetchQuote = async () => {
    let newPage = localStorage.getItem("pdfPage")
      ? localStorage.getItem("pdfPage")
      : 1;
    const pdfs = await getAllPdfs(false, newPage, pageSize);
    if(pdfs?.data){
      setPdfs(pdfs?.data);
      setPage(pdfs?.page);
      setTotalCount(pdfs?.total_count);
    }
  };

  const [showVideoVelocityTypePopup, setShowVideoVelocityTypePopup] =
    useState(false);
  const [velocityPdfId, setVelocityPdfId] = useState("");
  const toggleShowVelocityTypePopup = (id) => {
    setVelocityPdfId(id);
    setShowVideoVelocityTypePopup((prev) => !prev);
  };

  const [showDeckVelocityTypePopup, setShowDeckVelocityTypePopup] =
    useState(false);
  const toggleShowDeckVelocityTypePopup = (id) => {
    setVelocityPdfId(id);
    setShowDeckVelocityTypePopup((prev) => !prev);
  };

  return (
    <div className="overflow-x-auto">
      {showVideoVelocityTypePopup && (
        <VideoVelocityTypePopup
          pdfId={velocityPdfId}
          toggleOverlay={toggleShowVelocityTypePopup}
        />
      )}
      {showDeckVelocityTypePopup && (
        <DeckVelocityTypePopup
          pdfId={velocityPdfId}
          toggleOverlay={toggleShowDeckVelocityTypePopup}
        />
      )}
      <table className="table-auto min-w-full divide-y divide-gray-200">
        <thead>
          <tr className="bg-gray-50 text-left">
            <th
              scope="col"
              className="px-6 py-3 font-bold uppercase tracking-wider text-xl"
            >
              Title
            </th>
            {/* <th
              scope="col"
              className="px-6 py-3 font-bold uppercase tracking-wider text-xl"
            >
              Domain
            </th> */}

            <th
              scope="col"
              className="px-6 py-3 font-bold uppercase tracking-wider text-xl"
            >
              PDF Processing Status
            </th>

            <th
              scope="col"
              className="px-6 py-3 font-bold uppercase tracking-wider text-xl"
            >
              Interactive Document
            </th>

            <th
              scope="col"
              className="px-6 py-3 font-bold uppercase tracking-wider text-xl"
            >
              Video
            </th>

            <th
              scope="col"
              className="px-6 py-3 font-bold uppercase tracking-wider text-xl"
            >
              Generate Deck
            </th>

            <th
              scope="col"
              className="px-6 py-3 font-bold uppercase tracking-wider text-xl"
            >
              Outputs
            </th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
          {pdfs.map((ele) => (
            <PDFListRow
              pdfItem={ele}
              toggleShowProcessingOverlayNext={toggleShowProcessingOverlayNext}
              showVelocityTypePopup={toggleShowVelocityTypePopup}
              showDeckVelocityTypePopup={toggleShowDeckVelocityTypePopup}
            />
          ))}
        </tbody>
      </table>
      {Math.ceil(totalCount / pageSize) > 1 && (
        <Pagination
          curPage={page}
          totalPages={Math.ceil(totalCount / pageSize)}
          changePage={changePage}
        />
      )}
    </div>
  );
};

export { PDFList };
