import React, { useEffect, useState } from "react";

const FaqQuestion = ({ question, answer, isInRecordMode, scrollToBottom }) => {
  const [showAnswer, setShowAnswer] = useState(false);
  useEffect(() => {
    // scrollToBottom();
  }, [showAnswer]);
  const toggleShowAnswer = () => {
    console.log("Scrolling to bottom");
    setShowAnswer((prev) => !prev);
  };
  return (
    <div className="p-2 rounded-md ">
      <p
        className={
          "bg-[#cbcee7] text-black p-2 rounded-md cursor-pointer " +
          (isInRecordMode ? " text-lg " : "")
        }
        onClick={toggleShowAnswer}
      >
        {question}
      </p>
      {showAnswer && (
        <p
          className={
            "bg-[#d8def1] p-2 rounded-md mt-1 text-slate-800 " +
            (isInRecordMode ? " text-lg " : "")
          }
        >
          {answer}
        </p>
      )}
    </div>
  );
};

export { FaqQuestion };
