export { AnnotationPage } from "./AnnotationPage/AnnotationPage";
export { UploadPage } from "./Upload/UploadPage";
export { Home } from "./Home/Home";
export { InteractiveReadingPage } from "./InteractiveReadingPage/InteractiveReadingPage";
export { InteractiveReadingPageNew } from "./InteractiveReadingPage/InteractiveReadingPageNew";
export { OutputsPage } from "./OutputsPage/OutputsPage";
export { TerminologiesPage } from "./TerminologiesPage/TerminologiesPage";
export { VideoGenInputsPage } from "./VideoGenInputsPage/VideoGenInputsPage";
export { VideoInputsListPage } from "./VideoInputsListPage/VideoInputsListPage";
export { VideoThemeConfigPage } from "./VideoThemeConfigPage/VideoThemeConfigPage";
export { VideoAnnotationPage } from "./VideoAnnotationPage/VideoAnnotationPage";
export { VideoOutputPage } from "./VideoOutputPage/VideoOutputPage";
export { DeckAnnotationPage } from "./DeckAnnotationPage/DeckAnnotationPage";
export { DeckOutputPage } from "./DeckOutputPage/DeckOutputPage";
