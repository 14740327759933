/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from "react";
import * as pdfjsLib from "pdfjs-dist";
import { Button } from "../Button/Button";
import axios from "axios";
import {
  addToHistory,
  resetSelectedContext,
  setSelectedContext,
  toggleShowLoader,
} from "../../features";
import { getFAQs, getMenuItems, processUserQuery } from "../../utils";
import { useDispatch, useSelector } from "react-redux";
import "pdfjs-dist/web/pdf_viewer.css";
// Configure PDF.js worker
pdfjsLib.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjsLib.version}/pdf.worker.mjs`;
var selection = "";
const InteractivePDFRendererNew = ({
  resourceUrl,
  resourceId,
  outputId,
  referenceSection,
}) => {
  const dispatch = useDispatch();

  const containerRef = useRef(null);
  const pageRefs = useRef([]);
  const [pdf, setPdf] = useState(null);
  const [numPages, setNumPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [scale, setScale] = useState(1.5); // Initial zoom level
  const [selectionInfo, setSelectionInfo] = useState(null); // Store selection info
  const [tooltipInfo, setTooltipInfo] = useState(null); // Store tooltip info
  const [publications, setPublications] = useState([]);
  const [terminologies, setTerminologies] = useState([]);
  const [meshStatus, setMeshStatus] = useState("");
  const [tooltipHoverUp, setTooltipHoverUp] = useState(false);

  const playgroundState = useSelector((state) => state?.playground);
  const { domain } = playgroundState;
  useEffect(() => {
    const loadPdf = async () => {
      try {
        console.log("dddddddddddd");
        const loadingTask = pdfjsLib.getDocument(resourceUrl);
        const loadedPdf = await loadingTask.promise;
        setPdf(loadedPdf);
        setNumPages(loadedPdf.numPages);
        await renderPages(loadedPdf, scale);
      } catch (error) {
        console.error("Error loading PDF:", error);
      }
    };

    const getData = async () => {
      const meshStatusData = await axios.post(
        `${process.env.REACT_APP_BACKEND_BASE_URL}/get_mesh_status`,
        { resource_id: resourceId }
      );
      setMeshStatus(meshStatusData.data);
      const publicationsData = await axios.get(
        `${process.env.REACT_APP_BACKEND_BASE_URL}/publications?resource_id=${resourceId}`
      );
      // console.log(publicationsData?.data);
      setPublications(exit => [...publicationsData?.data]);
      localStorage.setItem("publications", JSON.stringify(publicationsData?.data))

      const terminologiesData = await axios.get(
        `${process.env.REACT_APP_BACKEND_BASE_URL}/terminologies?output_id=${outputId}`
      );
      // console.log("terminologiesData", terminologiesData?.data.length);
      if (terminologiesData?.data) {
        setTerminologies([...terminologiesData?.data]);
        localStorage.setItem("terminologies", JSON.stringify(terminologiesData?.data))
      }
      console.log(publicationsData, terminologiesData)
      loadPdf();
    };
    getData();
  }, [resourceUrl]);

  useEffect(() => {
    if (pdf) {
      renderPages(pdf, scale);
    }
  }, [publications, terminologies]);

  useEffect(() => {
    if (pdf) {
      renderPages(pdf, scale);
    }
  }, [scale]);

  const renderPages = async (pdfDocument, zoomLevel) => {
    // console.log("pdfDocument", pdfDocument, zoomLevel, containerRef.current);
    if (!pdfDocument || !containerRef.current) return;

    containerRef.current.innerHTML = ""; // Clear previous content
    pageRefs.current = []; // Reset page references

    for (let pageNumber = 1; pageNumber <= pdfDocument.numPages; pageNumber++) {
      const page = await pdfDocument.getPage(pageNumber);
      const viewport = page.getViewport({ scale: zoomLevel });

      const canvas = document.createElement("canvas");
      const context = canvas.getContext("2d");
      canvas.width = viewport.width;
      canvas.height = viewport.height;

      const renderContext = {
        canvasContext: context,
        viewport: viewport,
      };

      await page.render(renderContext).promise;

      // console.log(viewport.width, viewport.height);
      // Create a text layer container
      const textLayerDiv = document.createElement("div");
      textLayerDiv.className = "textLayer";
      // Style text layer for alignment with the viewport
      textLayerDiv.style.width = `${viewport.width}px`;
      textLayerDiv.style.height = `${viewport.height}px`;
      textLayerDiv.style.position = "absolute";
      textLayerDiv.style.top = "0";
      textLayerDiv.style.left = "0";
      // textLayerDiv.style.background = 'rgb(58 57 68 / 0.2)';
      textLayerDiv.style.setProperty("--scale-factor", viewport.scale);

      // Append canvas and text layer to the page container
      const pageContainer = document.createElement("div");
      pageContainer.style.position = "relative";
      pageContainer.style.display = "inline-flex";

      pageContainer.appendChild(canvas);
      pageContainer.appendChild(textLayerDiv);

      // Store page reference
      pageRefs.current.push(pageContainer);
      // console.log(pageNumber, pageNumber - 1, pageContainer)
      containerRef.current.appendChild(pageContainer);

      // Render text layer
      const textContent = await page.getTextContent();
      await renderTextLayer(textContent, textLayerDiv, viewport);

      // Add hyperlink for the target string
      console.log("publications", publications)
      const publicationsData = JSON.parse(localStorage.getItem('publications'));
      for (const publicationObj of publicationsData) {
        // console.log("publication", publicationObj);
        addHyperlinkToTextLayer(
          textLayerDiv,
          publicationObj.regex,
          publicationObj.url_path
        );
      }
      console.log("terminologies", terminologies)
      const terminologiesData = JSON.parse(localStorage.getItem('terminologies'));
      for (const terminology of terminologiesData) {
        addTooltipToTextLayer(textLayerDiv, terminology);
      }

      var tooltip = document.querySelectorAll(".tooltip-span");
      for (let i = 0; i < tooltip.length; i++) {
        tooltip[i].addEventListener("mouseover", handleTooltipMouseOver);
        tooltip[i].addEventListener("mouseout", handleTooltipMouseOut);
      }
    }
  };

  const renderTextLayer = async (textContent, container, viewport) => {
    // Create a text layer
    const textLayerBuilder = new pdfjsLib.TextLayer({
      textContentSource: textContent,
      container: container,
      viewport: viewport,
      textDivs: [],
    });

    // textLayerBuilder.setTextContent(textContent);
    textLayerBuilder.render();
  };

  const handleScroll = () => {
    if (!containerRef.current || !pageRefs.current) return;

    const containerTop = containerRef.current.scrollTop;
    const containerHeight = containerRef.current.offsetHeight;

    // console.log(pageRefs.current)
    for (let i = 0; i < pageRefs.current.length; i++) {
      const pageElement = pageRefs.current[i];
      // console.log("pageElement", i, pageRefs.current)
      const pageTop = pageElement.offsetTop;
      const pageHeight = pageElement.offsetHeight;

      if (
        pageTop < containerTop + containerHeight / 2 &&
        pageTop + pageHeight > containerTop + containerHeight / 2
      ) {
        // Update current page dynamically
        setCurrentPage(i + 1);
        break;
      }
    }
  };

  useEffect(() => {
    const container = containerRef.current;
    if (container) {
      container.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (container) {
        container.removeEventListener("scroll", handleScroll);
      }
    };
  }, []);

  const scrollToPage = (pageNumber) => {
    if (pageNumber < 1 || pageNumber > numPages) return; // Prevent out-of-bounds navigation

    const targetPage = pageRefs.current[pageNumber - 1];
    if (targetPage) {
      targetPage.scrollIntoView({ behavior: "smooth", block: "start" });
      setCurrentPage(pageNumber); // Update the current page
    }
  };

  const zoomIn = () => setScale((prevScale) => Math.min(prevScale + 0.25, 3));
  const zoomOut = () =>
    setScale((prevScale) => Math.max(prevScale - 0.25, 0.5));

  const handleTextSelection = async () => {
    selection = window.getSelection();
    
    if (selection && !selection.isCollapsed) {
      const range = selection.getRangeAt(0);
      const rect = range.getBoundingClientRect();
      const selectedText = selection.toString();

      // Optionally, find the page index based on the range
      const pageIndex = Array.from(containerRef.current.children).findIndex(
        (page) => page.contains(range.startContainer.parentNode)
      );

      if (pageIndex !== -1) {
        // console.log(pageIndex, range, selectedText);
        // highlightText(pageIndex, range, selectedText);
      }

      if (selectedText && selectedText != selectionInfo?.text) {
        const res = await getMenuItems(selectedText);
        setSelectionInfo({
          text: selectedText,
          bbox: rect,
          menuItems: res,
          x: rect.x,
          y: rect.y - 64 + rect.height,
        });
      }else{
        setSelectionInfo(null); // Clear selection if nothing is selected
      }

      // Clear selection
      // selection.removeAllRanges();
    } else {
      setSelectionInfo(null); // Clear selection if nothing is selected
    }
  };

  const highlightText = async (pageIndex, range, text) => {
    const page = await pdf.getPage(pageIndex + 1);
    const viewport = page.getViewport({ scale: scale });

    const container = containerRef.current;
    const pageContainer = pageRefs.current[pageIndex];
    if (!pageContainer) return;

    // Get bounding boxes for the selected range
    const rects = range.getClientRects();
    const highlightDiv = document.createElement("div");

    highlightDiv.className = "highlight-layer";
    highlightDiv.style.position = "absolute";
    // highlightDiv.style.setProperty('--scale-factor', viewport.scale);
    highlightDiv.style.width = `${viewport.width}px`;
    highlightDiv.style.height = `${viewport.height}px`;
    highlightDiv.style.top = 0;
    highlightDiv.style.left = 0;
    highlightDiv.style.pointerEvents = "none"; // Prevent interactions

    Array.from(rects).forEach((rect) => {
      const highlight = document.createElement("div");
      highlight.style.position = "absolute";
      highlight.style.backgroundColor = "rgba(255, 255, 0, 0.5)"; // Highlight color
      highlight.style.left = `${rect.left - pageContainer.offsetLeft}px`;
      highlight.style.top = `${
        rect.top - pageContainer.offsetTop + container.scrollTop
      }px`;
      highlight.style.width = `${rect.width}px`;
      highlight.style.height = `${rect.height}px`;
      highlightDiv.appendChild(highlight);
    });

    pageContainer.appendChild(highlightDiv);
  };
  const [highlights, setHighlights] = useState([]);
  const saveHighlight = (pageIndex, range, text) => {
    const rects = Array.from(range.getClientRects()).map((rect) => ({
      left: rect.left,
      top: rect.top,
      width: rect.width,
      height: rect.height,
    }));
    setHighlights((prev) => [...prev, { pageIndex, rects, text }]);
    // console.log('highlights', highlights);
  };

  const underlineText = async (pageIndex, range, text) => {
    const page = await pdf.getPage(pageIndex + 1);
    const viewport = page.getViewport({ scale: scale });

    const container = containerRef.current;
    const pageContainer = pageRefs.current[pageIndex];
    if (!pageContainer) return;

    // Get bounding boxes for the selected range
    const rects = range.getClientRects();
    const underlineDiv = document.createElement("div");

    underlineDiv.className = "underline-layer";
    underlineDiv.style.position = "absolute";
    // underlineDiv.style.setProperty('--scale-factor', viewport.scale);
    underlineDiv.style.width = `${viewport.width}px`;
    underlineDiv.style.height = `${viewport.height}px`;
    underlineDiv.style.top = 0;
    underlineDiv.style.left = 0;
    underlineDiv.style.pointerEvents = "none"; // Prevent interactions

    Array.from(rects).forEach((rect) => {
      const underline = document.createElement("div");
      underline.style.position = "absolute";
      underline.style.borderBottom = "2px solid red"; // Underline style
      underline.style.left = `${rect.left - pageContainer.offsetLeft}px`;
      underline.style.top = `${
        rect.bottom - pageContainer.offsetTop - 2 + container.scrollTop
      }px`; // Slightly below the text
      underline.style.width = `${rect.width}px`;
      underlineDiv.appendChild(underline);
    });

    pageContainer.appendChild(underlineDiv);
  };
  const [underlines, setUnderlines] = useState([]);
  const saveUnderline = (pageIndex, range) => {
    const rects = Array.from(range.getClientRects()).map((rect) => ({
      left: rect.left,
      top: rect.top,
      width: rect.width,
      height: rect.height,
    }));
    setUnderlines((prev) => [...prev, { pageIndex, rects }]);
  };

  const addHyperlinkToTextLayer = (textLayerDiv, searchString, hyperlink) => {
    // Find all spans in the text layer
    const spans = textLayerDiv.querySelectorAll("span");
    for (const span of spans) {
      const newRegex = new RegExp(searchString, "gi");
      const matchData = span.textContent.match(newRegex);
      if (matchData) {
        // Create an anchor element
        const anchor = `<a class="anchor-link" href='${hyperlink}${matchData[0]}' target = '_blank'>${matchData[0]}</a>`;
        // Replace the span content with the anchor
        span.innerHTML = span.textContent.replace(matchData[0], anchor);
        break;
      }
    }
  };

  const addTooltipToTextLayer = (textLayerDiv, terminology) => {
    // Find all spans in the text layer
    const spans = textLayerDiv.querySelectorAll("span");
    for (const span of spans) {
      // console.log("addTooltipToTextLayer", span.textContent);
      const newRegex = new RegExp(terminology.regex, "gi");
      const matchData = span.textContent.match(newRegex);
      if (
        matchData &&
        matchData[0] &&
        terminology.matched_words.includes(matchData[0])
      ) {
        // Create an anchor element
        const spanAppend = `<span class="tooltip-span" data-title="${terminology.definition}" data-id="${terminology._id}">${matchData[0]}</span>`;
        // Replace the span content with the anchor
        span.innerHTML = span.textContent.replace(matchData[0], spanAppend);
        break;
      }
    }
  };

  const handleTooltipMouseOver = (event) => {
    const span = event.target;
    const spanXY = span.getBoundingClientRect();
    // We want the tooltip to completely obscure the element, so that we dont get a mouse-out event
    const tooltipX = spanXY.x > 200 ? spanXY.x - 200 : spanXY.x;
    const tooltipY =
      window.innerHeight > spanXY.y + spanXY.height + 135
        ? spanXY.y - 64 + spanXY.height
        : spanXY.y - 64 - 130;
    setTooltipInfo({
      _id: span.getAttribute("data-id"),
      title: span.textContent,
      text: span.getAttribute("data-title"),
      x: tooltipX,
      y: tooltipY,
    });
  };

  const handleTooltipMouseOut = (event) => {
    setTimeout(()=>{
      const tooltip = document.getElementsByClassName('tooltip')
      console.log("tooltip", tooltip)
      if(tooltip && tooltip[0]){
        const show = tooltip[0].getAttribute("data-show");
        if(!show){
          setTooltipInfo(null);
        }
      }
    }, 300)
  };

  const handleTooltipHoverUp = () => {
    document.getElementsByClassName('tooltip')[0].setAttribute("data-show", 1);
    setTooltipHoverUp(true);
  };

  const handleTooltipHoverOut = () => {
    if(tooltipHoverUp){
      setTooltipInfo(null);
      setTooltipHoverUp(false);
    }
  };
  const handlePdfEvent = async (eventName, data) => {
    // Clear selection
    if(selection)
      selection.removeAllRanges();
    switch (eventName) {
      case "TERMINOLOGY_MORE_INFO_CLICKED":
        setSelectionInfo(null); // Clear selection if nothing is selected
        const userMessage = {
          sender: "user",
          message: `Define :  ${data.title}`,
        };
        dispatch(addToHistory(userMessage));
        const botResponse = await getFAQs(data?._id);
        const botMessage = {
          sender: "bot",
          type: "faq",
          term: data.term,
          faqs: botResponse,
          page: currentPage,
        };
        dispatch(addToHistory(botMessage));
        break;
      case "TEXT_SELECTION_CONTEXT_MENU_ITEM_ASK_A_QUESTION":
        setSelectionInfo(null);
        dispatch(
          setSelectedContext({
            text: data.text,
            page: currentPage,
            bbox: data.bbox,
          })
        );
        break;
      case "TEXT_SELECTION_CONTEXT_MENU_ITEM_SUMMARIZE":
      case "TEXT_SELECTION_CONTEXT_MENU_ITEM_EXPLAIN":
      case "TEXT_SELECTION_CONTEXT_MENU_ITEM_DEFINE":
        if (selectionInfo.menuItems?.is_term) {
          data.item = "Define";
        } else if (selectionInfo.menuItems?.is_explainable) {
          data.item = "Explain";
        } else if (selectionInfo.menuItems?.is_summarizable) {
          data.item = "Summarize";
        } else if (
          selectionInfo.menuItems?.is_explainable ||
          selectionInfo.menuItems?.is_summarizable
        ) {
          data.item = "Ask a Question";
        }
        setSelectionInfo(null);
        const userMessageSum = {
          sender: "user",
          message: `${data.item} :  ${data.text}`,
        };
        dispatch(addToHistory(userMessageSum));
        const botResponseSum = await processUserQuery({
          type: data.item,
          text: data.text,
          page: currentPage,
          bbox: data.bbox,
          domain,
          resourceId,
        });
        const botMessageSum = {
          sender: "bot",
          message: botResponseSum,
        };
        dispatch(addToHistory(botMessageSum));
        break;
      default:
        break;
    }
    setSelectionInfo(null); // Clear selection if nothing is selected
  };

  return (
    <div
      className="mr-1 rounded-lg border-1 border-dashed w-full border-slate-800/70"
      id="pdf-viewer-pane"
    >
      <div style={{ display: "grid", height: "calc(100vh - 92px)" }}>
        <div id="pdf-toolbar" className="m-0">
          <div>
            <p>Mesh Status - {meshStatus}</p>
          </div>
          <div id="pdf-navigation" className="flex flex-row justify-center">
            <Button
              onClick={zoomOut}
              className="border-slate-600 px-2 mx-1 rounded-full border-1"
            >
              -
            </Button>
            <div
              id="pdf-page-indicator"
              className="border-slate-600 px-2 mx-1 rounded border"
            >
              {(scale * 100).toFixed(0) - 50}%
            </div>
            <Button
              onClick={zoomIn}
              className="border-slate-600 px-2 mx-1 rounded-full border-1"
            >
              +
            </Button>
            &nbsp; &nbsp;
            <div id="pdf-navigation" className="flex flex-row justify-center">
              <Button
                onClick={() => scrollToPage(currentPage - 1)}
                disabled={currentPage === 1}
                className="border-slate-600 px-2 mx-1 rounded-full border-1"
              >
                Prev
              </Button>
              <div
                id="pdf-page-indicator"
                className="border-slate-600 px-2 mx-1 rounded border"
              >
                <div>{currentPage}</div> <div>/</div> <div>{numPages}</div>
              </div>
              <Button
                onClick={() => scrollToPage(currentPage + 1)}
                disabled={currentPage === numPages}
                className="border-slate-600 px-2 mx-1 rounded-full border-1"
              >
                Next
              </Button>
            </div>
          </div>
        </div>

        <div
          id="pdf-viewer-placeholder"
          ref={containerRef}
          align="center"
          style={{
            overflowY: "auto",
            // padding: '20px',
            background: "#efefef",
            boxShadow: "0px 0px 5px rgba(0,0,0,0.2)",
          }}
          onMouseUp={handleTextSelection}
        ></div>
        {/* Action button */}
        {selectionInfo && (
          <div
            style={{
              top: `${selectionInfo.y}px`,
              left: `${selectionInfo.x}px`,
              display: "block",
              position: "absolute",
            }}
          >
            {selectionInfo.menuItems?.is_term && (
              <button
                className="action-button"
                onClick={(e) => {
                  handlePdfEvent(
                    "TEXT_SELECTION_CONTEXT_MENU_ITEM_DEFINE",
                    selectionInfo
                  );
                }}
              >
                Define
              </button>
            )}
            {selectionInfo.menuItems?.is_explainable && (
              <button
                className="action-button"
                onClick={(e) => {
                  handlePdfEvent(
                    "TEXT_SELECTION_CONTEXT_MENU_ITEM_EXPLAIN",
                    selectionInfo
                  );
                }}
              >
                Explain
              </button>
            )}
            {selectionInfo.menuItems?.is_summarizable && (
              <button
                className="action-button"
                onClick={(e) => {
                  handlePdfEvent(
                    "TEXT_SELECTION_CONTEXT_MENU_ITEM_SUMMARIZE",
                    selectionInfo
                  );
                }}
              >
                Summarize
              </button>
            )}
            {(selectionInfo.menuItems?.is_explainable ||
              selectionInfo.menuItems?.is_summarizable) && (
              <button
                className="action-button"
                onClick={(e) => {
                  handlePdfEvent(
                    "TEXT_SELECTION_CONTEXT_MENU_ITEM_ASK_A_QUESTION",
                    selectionInfo
                  );
                }}
              >
                Ask a Question
              </button>
            )}
          </div>
        )}
        {/* Action button */}
        {tooltipInfo && (
          <div
            className="tooltip"
            style={{
              top: `${tooltipInfo.y}px`,
              left: `${tooltipInfo.x}px`,
              display: "block",
            }}
            onMouseEnter={(e) => handleTooltipHoverUp()}
            onMouseLeave={(e) => handleTooltipHoverOut()}
          >
            <p>
              <b>{tooltipInfo.title} :</b>
            </p>
            <p style={{ height: "54px", overflow: "hidden", fontSize: "12px" }}>
              {tooltipInfo.text}
            </p>
            <a
              onClick={(e) => {
                handlePdfEvent("TERMINOLOGY_MORE_INFO_CLICKED", tooltipInfo);
              }}
              style={{ color: "blue" }}
            >
              Learn More...
            </a>
          </div>
        )}
      </div>
    </div>
  );
};

export { InteractivePDFRendererNew };
