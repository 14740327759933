/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from "react";
import * as pdfjsLib from "pdfjs-dist";
import { Button } from "../Button/Button";
import "pdfjs-dist/web/pdf_viewer.css";
// Configure PDF.js worker
pdfjsLib.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjsLib.version}/pdf.worker.mjs`;

const terminologies = [
  {
    _id: "673afff07070569b9eb70dd9",
    term: "Torsades de Pointes",
    matched_words: ["Torsades de Pointes"],
    definition:
      "A malignant form of polymorphic ventricular tachycardia that is characterized by HEART RATE between 200 and 250 beats per minute, and QRS complexes with changing amplitude and twisting of the points. The term also describes the syndrome of tachycardia with prolonged ventricular repolarization, long QT intervals exceeding 500 milliseconds or BRADYCARDIA. Torsades de pointes may be self-limited or may progress to VENTRICULAR FIBRILLATION.",
    regex:
      "(\\bT-?\\s*o-?\\s*r-?\\s*s-?\\s*a-?\\s*d-?\\s*e-?\\s*s-?\\s*\\-?\\s* -?\\s*d-?\\s*e-?\\s*\\-?\\s* -?\\s*P-?\\s*o-?\\s*i-?\\s*n-?\\s*t-?\\s*e-?\\s*s\\b)",
    term_id: "665ff73549250420296d04f2",
    resource_id: "673af66adf73c7288af83ba7",
  },
  {
    _id: "673b00097070569b9eb70ddb",
    term: "Prednisone",
    matched_words: ["prednisone", "Prednisone"],
    definition:
      "A synthetic anti-inflammatory glucocorticoid derived from CORTISONE. It is biologically inert and converted to PREDNISOLONE in the liver.",
    regex:
      "(\\bp-?\\s*r-?\\s*e-?\\s*d-?\\s*n-?\\s*i-?\\s*s-?\\s*o-?\\s*n-?\\s*e\\b|\\bP-?\\s*r-?\\s*e-?\\s*d-?\\s*n-?\\s*i-?\\s*s-?\\s*o-?\\s*n-?\\s*e\\b)",
    term_id: "665ff73549250420296cf37f",
    resource_id: "673af66adf73c7288af83ba7",
  },
];
const PDFRendererNew = ({ 
    canvasRef,
    pdfRendererRef,
    fabricCanvasRef,
    resourceUrl
}) => {
  const containerRef = useRef(null);
  const pageRefs = useRef([]);
  const [pdf, setPdf] = useState(null);
  const [numPages, setNumPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [scale, setScale] = useState(1.25); // Initial zoom level
  const [selectionInfo, setSelectionInfo] = useState(null); // Store selection info
  const [tooltipInfo, setTooltipInfo] = useState(null); // Store tooltip info

  useEffect(() => {
    const loadPdf = async () => {
      try {
        const loadingTask = pdfjsLib.getDocument(resourceUrl);
        const loadedPdf = await loadingTask.promise;
        setPdf(loadedPdf);
        setNumPages(loadedPdf.numPages);
        await renderPages(loadedPdf, scale);
      } catch (error) {
        console.error("Error loading PDF:", error);
      }
    };

    loadPdf();
  }, [resourceUrl]);

  useEffect(() => {
    if (pdf) {
      renderPages(pdf, scale);
    }
  }, [scale]);

  const renderPages = async (pdfDocument, zoomLevel) => {
    console.log("pdfDocument", pdfDocument, zoomLevel, containerRef.current)
    if (!pdfDocument || !containerRef.current) return;

    containerRef.current.innerHTML = ""; // Clear previous content
    pageRefs.current = []; // Reset page references

    for (let pageNumber = 1; pageNumber <= pdfDocument.numPages; pageNumber++) {
      const page = await pdfDocument.getPage(pageNumber);
      const viewport = page.getViewport({ scale: zoomLevel });

      const canvas = document.createElement("canvas");
      const context = canvas.getContext("2d");
      canvas.width = viewport.width;
      canvas.height = viewport.height;

      const renderContext = {
        canvasContext: context,
        viewport: viewport,
      };

      await page.render(renderContext).promise;

      // console.log(viewport.width, viewport.height);
      // Create a text layer container
      const textLayerDiv = document.createElement("div");
      textLayerDiv.className = "textLayer";
      // Style text layer for alignment with the viewport
      textLayerDiv.style.width = `${viewport.width}px`;
      textLayerDiv.style.height = `${viewport.height}px`;
      textLayerDiv.style.position = "absolute";
      textLayerDiv.style.top = "0";
      textLayerDiv.style.left = "0";
      // textLayerDiv.style.background = 'rgb(58 57 68 / 0.2)';
      textLayerDiv.style.setProperty("--scale-factor", viewport.scale);

      // Append canvas and text layer to the page container
      const pageContainer = document.createElement("div");
      pageContainer.style.position = "relative";
      pageContainer.style.display = "inline-flex";

      pageContainer.appendChild(canvas);
      pageContainer.appendChild(textLayerDiv);

      // Store page reference
      // pageRefs.current[pageNumber - 1] = pageContainer;
      // Store page reference
      pageRefs.current.push(pageContainer);
      containerRef.current.appendChild(pageContainer);

      // Render text layer
      const textContent = await page.getTextContent();
      await renderTextLayer(textContent, textLayerDiv, viewport);
    }
  };

  const renderTextLayer = async (textContent, container, viewport) => {
    // console.log('Viewport width:', viewport.width, 'Viewport height:', viewport.height);
    // console.log('TextLayer dimensions:', container.style.width, container.style.height);
    // Create a text layer
    const textLayerBuilder = new pdfjsLib.TextLayer({
      textContentSource: textContent,
      container: container,
      viewport: viewport,
      textDivs: [],
    });

    // textLayerBuilder.setTextContent(textContent);
    textLayerBuilder.render();
  };

  const handleScroll = () => {
    if (!containerRef.current || !pageRefs.current) return;

    const containerTop = containerRef.current.scrollTop;
    const containerHeight = containerRef.current.offsetHeight;

    for (let i = 0; i < pageRefs.current.length; i++) {
      const pageElement = pageRefs.current[i];
      const pageTop = pageElement.offsetTop;
      const pageHeight = pageElement.offsetHeight;

      if (
        pageTop < containerTop + containerHeight / 2 &&
        pageTop + pageHeight > containerTop + containerHeight / 2
      ) {
        // Update current page dynamically
        setCurrentPage(i + 1);
        break;
      }
    }
  };

  useEffect(() => {
    const container = containerRef.current;
    if (container) {
      container.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (container) {
        container.removeEventListener("scroll", handleScroll);
      }
    };
  }, []);

  const scrollToPage = (pageNumber) => {
    if (pageNumber < 1 || pageNumber > numPages) return; // Prevent out-of-bounds navigation

    const targetPage = pageRefs.current[pageNumber - 1];
    if (targetPage) {
      targetPage.scrollIntoView({ behavior: "smooth", block: "start" });
      setCurrentPage(pageNumber); // Update the current page
    }
  };

  const zoomIn = () => setScale((prevScale) => Math.min(prevScale + 0.25, 3));
  const zoomOut = () =>
    setScale((prevScale) => Math.max(prevScale - 0.25, 0.5));

  const handleTextSelection = () => {
    const selection = window.getSelection();
    if (selection && !selection.isCollapsed) {
      const range = selection.getRangeAt(0);
      const rect = range.getBoundingClientRect();
      const selectedText = selection.toString();

      // Optionally, find the page index based on the range
      const pageIndex = Array.from(containerRef.current.children).findIndex(
        (page) => page.contains(range.startContainer.parentNode)
      );

      if (pageIndex !== -1) {
        // console.log(pageIndex, range, selectedText);
        // highlightText(pageIndex, range, selectedText);
      }

      if (selectedText) {
        setSelectionInfo({
          text: selectedText,
          x: rect.left + window.scrollX,
          y: rect.top + window.scrollY,
        });
      }

      // Clear selection
      // selection.removeAllRanges();
    } else {
      setSelectionInfo(null); // Clear selection if nothing is selected
    }
  };

  const highlightText = async (pageIndex, range, text) => {
    const page = await pdf.getPage(pageIndex + 1);
    const viewport = page.getViewport({ scale: scale });

    const container = containerRef.current;
    const pageContainer = pageRefs.current[pageIndex];
    if (!pageContainer) return;

    // Get bounding boxes for the selected range
    const rects = range.getClientRects();
    const highlightDiv = document.createElement("div");

    highlightDiv.className = "highlight-layer";
    highlightDiv.style.position = "absolute";
    // highlightDiv.style.setProperty('--scale-factor', viewport.scale);
    highlightDiv.style.width = `${viewport.width}px`;
    highlightDiv.style.height = `${viewport.height}px`;
    highlightDiv.style.top = 0;
    highlightDiv.style.left = 0;
    highlightDiv.style.pointerEvents = "none"; // Prevent interactions

    Array.from(rects).forEach((rect) => {
      const highlight = document.createElement("div");
      highlight.style.position = "absolute";
      highlight.style.backgroundColor = "rgba(255, 255, 0, 0.5)"; // Highlight color
      highlight.style.left = `${rect.left - pageContainer.offsetLeft}px`;
      highlight.style.top = `${
        rect.top - pageContainer.offsetTop + container.scrollTop
      }px`;
      highlight.style.width = `${rect.width}px`;
      highlight.style.height = `${rect.height}px`;
      highlightDiv.appendChild(highlight);
    });

    pageContainer.appendChild(highlightDiv);
  };
  const [highlights, setHighlights] = useState([]);
  const saveHighlight = (pageIndex, range, text) => {
    const rects = Array.from(range.getClientRects()).map((rect) => ({
      left: rect.left,
      top: rect.top,
      width: rect.width,
      height: rect.height,
    }));
    setHighlights((prev) => [...prev, { pageIndex, rects, text }]);
    // console.log('highlights', highlights);
  };

  const underlineText = async (pageIndex, range, text) => {
    const page = await pdf.getPage(pageIndex + 1);
    const viewport = page.getViewport({ scale: scale });

    const container = containerRef.current;
    const pageContainer = pageRefs.current[pageIndex];
    if (!pageContainer) return;

    // Get bounding boxes for the selected range
    const rects = range.getClientRects();
    const underlineDiv = document.createElement("div");

    underlineDiv.className = "underline-layer";
    underlineDiv.style.position = "absolute";
    // underlineDiv.style.setProperty('--scale-factor', viewport.scale);
    underlineDiv.style.width = `${viewport.width}px`;
    underlineDiv.style.height = `${viewport.height}px`;
    underlineDiv.style.top = 0;
    underlineDiv.style.left = 0;
    underlineDiv.style.pointerEvents = "none"; // Prevent interactions

    Array.from(rects).forEach((rect) => {
      const underline = document.createElement("div");
      underline.style.position = "absolute";
      underline.style.borderBottom = "2px solid red"; // Underline style
      underline.style.left = `${rect.left - pageContainer.offsetLeft}px`;
      underline.style.top = `${
        rect.bottom - pageContainer.offsetTop - 2 + container.scrollTop
      }px`; // Slightly below the text
      underline.style.width = `${rect.width}px`;
      underlineDiv.appendChild(underline);
    });

    pageContainer.appendChild(underlineDiv);
  };
  const [underlines, setUnderlines] = useState([]);
  const saveUnderline = (pageIndex, range) => {
    const rects = Array.from(range.getClientRects()).map((rect) => ({
      left: rect.left,
      top: rect.top,
      width: rect.width,
      height: rect.height,
    }));
    setUnderlines((prev) => [...prev, { pageIndex, rects }]);
  };

  const addHyperlinkToTextLayer = (textLayerDiv, searchString, hyperlink) => {
    // Find all spans in the text layer
    const spans = textLayerDiv.querySelectorAll("span");
    for (const span of spans) {
      const newRegex = new RegExp(searchString, "gi");
      const matchData = span.textContent.match(newRegex);
      if (matchData) {
        // Create an anchor element
        const anchor = `<a class="anchor-link" href='${hyperlink}' target = '_blank'>${matchData[0]}</a>`;
        // Replace the span content with the anchor
        span.innerHTML = span.textContent.replace(matchData[0], anchor);
        break;
      }
    }
  };

  const addTooltipToTextLayer = (textLayerDiv, terminology) => {
    // Find all spans in the text layer
    const spans = textLayerDiv.querySelectorAll("span");
    for (const span of spans) {
      console.log("addTooltipToTextLayer", span.textContent);
      const newRegex = new RegExp(terminology.regex, "gi");
      const matchData = span.textContent.match(newRegex);
      if (
        matchData &&
        matchData[0] &&
        terminology.matched_words.includes(matchData[0])
      ) {
        // Create an anchor element
        const spanAppend = `<span class="tooltip-span" data-title="${terminology.definition}" data-id="${terminology._id}">${matchData[0]}</span>`;
        // Replace the span content with the anchor
        span.innerHTML = span.textContent.replace(matchData[0], spanAppend);
        break;
      }
    }
  };

  const handleTooltipMouseOver = (event) => {
    const span = event.target;
    console.log("handleTooltipMouseOver", span.attributes);
    setTooltipInfo({
      _id: span.getAttribute("data-id"),
      title: span.textContent,
      text: span.getAttribute("data-title"),
      x: event.pageX - 200,
      y: event.pageY - 120,
    });
  };

  const handleTooltipMouseOut = () => {
    setTooltipInfo(null);
  };

  return (
    <div
      className="mr-1 rounded-lg border-1 border-dashed w-full border-slate-800/70"
      id="pdf-viewer-pane"
    >
      <div style={{ display: 'grid', height: 'calc(100vh - 100px)' }}>
      <div id="pdf-toolbar" className="m-0">
        <div>
        </div>
        <div id="pdf-navigation" className="flex flex-row justify-center">
          <Button onClick={zoomOut} className="border-slate-600 px-2 mx-1 rounded-full border-1">
            -
          </Button>
          <div id="pdf-page-indicator" className="border-slate-600 px-2 mx-1 rounded border" >
             {(scale * 100).toFixed(0) - 25}%
          </div>
          <Button onClick={zoomIn} className="border-slate-600 px-2 mx-1 rounded-full border-1" >
            +
          </Button>
          &nbsp; &nbsp;
          <div id="pdf-navigation" className="flex flex-row justify-center">
            <Button onClick={() => scrollToPage(currentPage - 1)} disabled={currentPage === 1}
              className="border-slate-600 px-2 mx-1 rounded-full border-1"
            >
              Prev
            </Button>
            <div
              id="pdf-page-indicator"
              className="border-slate-600 px-2 mx-1 rounded border"
            >
              <div>{currentPage}</div> <div>/</div> <div>{numPages}</div>
            </div>
            <Button onClick={() => scrollToPage(currentPage + 1)} disabled={currentPage === numPages}
              className="border-slate-600 px-2 mx-1 rounded-full border-1"
            >
              Next
            </Button>
          </div>
        </div>
      </div>
      
        <div
            id="pdf-viewer-placeholder"
            ref={containerRef}
            align="center"
            style={{
                overflowY: 'auto',
                // padding: '20px',
                background: '#efefef',
                boxShadow: '0px 0px 5px rgba(0,0,0,0.2)'
            }}
            onMouseUp={handleTextSelection}
        ></div>
      </div>
    </div>
  );
};

export { PDFRendererNew };
