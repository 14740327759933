import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Chat, InteractivePDFRendererNew } from "../../components";
import { useDispatch } from "react-redux";
import { setDomain, setPrompts, toggleRecordMode } from "../../features";
import { getOutputDetails, getPdfData, getPrompts } from "../../utils";
import axios from "axios";

const InteractiveReadingPageNew = () => {
  const { outputId } = useParams();
  const [resourceId, setResourceId] = useState(null);
  const [referenceSection, setReferenceSection] = useState({
    pageNum: Number.MAX_SAFE_INTEGER,
    blockId: Number.MAX_SAFE_INTEGER,
  });
  const [resourceUrl, setResourceUrl] = useState("");
  const dispatch = useDispatch();
  // useState(() => {
  //   (async function () {
  //     try {
  //       const response = await getPdfData(resourceId);
  //       console.log("\t\t\tresponse : ", { response });
  //       const newDomain = response?.domain;
  //       dispatch(setDomain(newDomain));
  //     } catch (e) {
  //       console.error(e);
  //     }
  //   })();
  // }, [resourceId]);

  // const [isInRecordMode, setIsInRecordMode] = useState(false);

  useEffect(() => {
    const handleKeyPress = (event) => {
      console.log(event.metaKey);
      if (event.key === "r" && event.altKey) {
        dispatch(toggleRecordMode());
      }
    };

    document.addEventListener("keydown", handleKeyPress);

    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, []);

  useEffect(() => {
    (async function () {
      try {
        const outputDetails = await getOutputDetails(outputId);
        console.log({ outputDetails });
        const {
          resourceId,
          domain,
          pdfUrl,
          reference_section,
        } = outputDetails;
        document.title = `${outputDetails?.title} | Interactive Pdf`;
        dispatch(setDomain(domain));
        setResourceId(resourceId);
        setResourceUrl(pdfUrl);
        setReferenceSection(reference_section)
      } catch (e) {
        console.error(e);
      }
    })();
  }, [outputId]);

  return (
    <div className="flex flex-col items-center justify-center mx-6 relative" style={{height:'Calc( 100vh - 92px)'}}>
      <div className="flex flex-row w-full grow overflow-y-hidden no-scrollbar">
        {resourceUrl && (
          <>
            <InteractivePDFRendererNew
              resourceUrl={resourceUrl}
              resourceId={resourceId}
              outputId={outputId}
              referenceSection={referenceSection}
            />
            <Chat resourceId={resourceId} />
          </>
        )}
      </div>
    </div>
  );
};

export { InteractiveReadingPageNew };
