import React, { useState } from 'react';

const ReadMoreComponent = ({ children, maxCharacters = 100 }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  // Function to truncate content
  const getTruncatedContent = () => {
    let truncated = children;
    if(children.length > maxCharacters){
      truncated = children.substring(0, maxCharacters) + '...';
    }
    return truncated;
  };

  return (
    <div>
      {isExpanded ? children : getTruncatedContent()}
      { children.length > maxCharacters &&
        <button onClick={() => setIsExpanded(!isExpanded)} style={{textDecoration:"underline", padding: "0 5px"}}>
          {isExpanded ? 'Read Less' : 'Read More'}
        </button>
      }
    </div>
  );
};

export default ReadMoreComponent;
