import React, { useRef, useState } from "react";
import { FaCheck } from "react-icons/fa";
import {
  MdDelete,
  MdModeEdit,
  MdArrowDownward,
  MdArrowUpward,
} from "react-icons/md";
import { Input } from "../Input/Input";
import { DeckBlockItem } from "../DeckBlockItem/DeckBlockItem";

const DeckShotItem = ({
  total,
  index,
  shotId,
  title,
  blocks,
  handleTitleUpdate,
  handleSubTitleUpdate,
  handleVoiceoverUpdate,
  handleDeleteBlock,
  handleDeleteShot,
  handleDisplayTextUpdate,
  handleIndexChange,
}) => {
  const [isTitleEdit, setIsTitleEdit] = useState(false);
  const titleInputRef = useRef(null);

  const toggleTitleEdit = () => {
    setIsTitleEdit((prev) => !prev);
  };

  return (
    <div>
      <div className="bg-slate-100 p-2 flex justify-between items-center rounded-t-lg">
        <div className="flex items-center gap-3 rounded-t-lg">
          {isTitleEdit ? (
            <Input
              value={title}
              // updateValue={() => handleTitleUpdate({shotId, })}
              onChange={(e) =>
                handleTitleUpdate({ shotId, newTitle: e.target.value })
              }
              classes={" ml-2"}
              eleRef={titleInputRef}
            />
          ) : (
            <h2 className=" text-xl font-bold ml-2">{title}</h2>
          )}

          {title.toLowerCase() != "introduction" &&
            (isTitleEdit ? (
              <div className="cursor-pointer">
                <FaCheck onClick={toggleTitleEdit} />
              </div>
            ) : (
              <div className="cursor-pointer">
                <MdModeEdit onClick={toggleTitleEdit} />
              </div>
            ))}
        </div>

        <div className="flex">
          {index > 0 && (
            <div className="cursor-pointer">
              <MdArrowUpward onClick={() => handleIndexChange(index, "up")} />
            </div>
          )}
          {total != index + 1 && (
            <div className="cursor-pointer">
              <MdArrowDownward
                onClick={() => handleIndexChange(index, "down")}
              />
            </div>
          )}
          <div className="cursor-pointer">
            <MdDelete onClick={() => handleDeleteShot(shotId)} />
          </div>
        </div>
      </div>
      {blocks.map((block) => {
        const {
          id: blockID,
          type,
          displayText,
          url,
          bBox,
          pageNumber,
          voiceoverText,
        } = block;
        return (
          <div
            key={blockID}
            className="flex flex-col gap-3 m-2 py-2 bg-slate-100 rounded-md"
          >
            <DeckBlockItem
              shotId={shotId}
              block={block}
              handleSubTitleUpdate={handleSubTitleUpdate}
              handleDisplayTextUpdate={handleDisplayTextUpdate}
              handleVoiceoverUpdate={handleVoiceoverUpdate}
              handleDeleteBlock={handleDeleteBlock}
              subHeadingEdit={title.toLowerCase() != "introduction"}
            />
          </div>
        );
      })}
    </div>
  );
};

export { DeckShotItem };
