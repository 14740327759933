// import "@vidstack/react/player/styles/base.css";

// import { useEffect, useRef } from "react";

// import {
//   isHLSProvider,
//   MediaPlayer,
//   MediaProvider,
//   Poster,
//   Track,
// } from "@vidstack/react";

// import { VideoLayout } from "./components/layouts/video-layout";
// import { textTracks } from "./tracks";

// function VideoPlayer() {
//   let player = useRef(null);

//   useEffect(() => {
//     // Subscribe to state updates.
//     return player?.current?.subscribe(({ paused, viewType }) => {
//       // console.log('is paused?', '->', state.paused);
//       // console.log('is audio view?', '->', state.viewType === 'audio');
//     });
//   }, []);

//   function onProviderChange(provider, nativeEvent) {
//     // We can configure provider's here.
//     if (isHLSProvider(provider)) {
//       provider.config = {};
//     }
//   }

//   // We can listen for the `can-play` event to be notified when the player is ready.
//   function onCanPlay(detail, nativeEvent) {
//     // ...
//   }

//   return (
//     <MediaPlayer
//       className="w-full aspect-video bg-slate-900 text-white font-sans overflow-hidden rounded-md ring-media-focus data-[focus]:ring-4"
//       title="Sprite Fight"
//       src="https://files.vidstack.io/sprite-fight/720p.mp4"
//       crossOrigin
//       playsInline
//       onProviderChange={onProviderChange}
//       onCanPlay={onCanPlay}
//       ref={player}
//     >
//       <MediaProvider>
//         <Poster
//           className="absolute inset-0 block h-full w-full rounded-md opacity-0 transition-opacity data-[visible]:opacity-100 object-cover"
//           src="https://files.vidstack.io/sprite-fight/poster.webp"
//           alt="Girl walks into campfire with gnomes surrounding her friend ready for their next meal!"
//         />
//         {textTracks.map((track) => (
//           <Track {...track} key={track.src} />
//         ))}
//       </MediaProvider>

//       <VideoLayout thumbnails="https://files.vidstack.io/sprite-fight/thumbnails.vtt" />
//     </MediaPlayer>
//   );
// }

// export { VideoPlayer };

import "@vidstack/react/player/styles/default/theme.css";
import "@vidstack/react/player/styles/default/layouts/video.css";
import {
  useMediaRemote,
  MediaController,
  useMediaState,
} from "@vidstack/react";

import { textTracks } from "./tracks";

import { MediaPlayer, Track, MediaProvider } from "@vidstack/react";
import {
  defaultLayoutIcons,
  DefaultVideoLayout,
} from "@vidstack/react/player/layouts/default";
import { useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { ChaptersPreviewBar } from "../ChaptersPreviewBar/ChaptersPreviewBar";

const CustomPlayButton = ({ children }) => {
  console.log("CustomPlayButton : ", { children });
  return <div>Button here</div>;
};

const VideoPlayer = ({
  videoUrl,
  resourceId,
  thumbnailsUrl,
  tracks,
  title,
}) => {
  const remoteRef = useRef(null);

  console.log({ tracks });

  // Initialize the media remote
  const remote = useMediaRemote(remoteRef);

  // const playerRef = useRef(null);
  // const mediaState = useMediaState(remoteRef);
  // const isPlaying = mediaState.playing;

  const isPaused = useMediaState("paused", remoteRef);

  const chatState = useSelector((state) => state?.chat);
  const { history: chatHistory, showLoader, inputText } = chatState;

  const chaptersListRef = useRef(null);
  useEffect(() => {
    if (!isPaused) {
      remote.pause();
    }
  }, [inputText]);

  const seekAndPlay = (timeStamp = 75) => {
    console.log("Seek video : ", { timeStamp });
    remote.seek(timeStamp);
  };
  return (
    <div className="flex flex-col items-center px-4 h-[100%]">
      <div id="video-box" className="w-full max-w-[60rem] h-[100%]">
        <MediaPlayer
          ref={remoteRef}
          // title="Tirzepatide Once Weekly for the Treatment of Obesity"
          // src="https://storage.googleapis.com/tsoai-resources-data/Tirzepatide_v1.mp4"
          // title="Grill"
          // src="https://storage.googleapis.com/tsoai-resources-data/lowes_v1.mp4"
          title={title}
          src={videoUrl}
          className="w-full"
          height={3000}
          width={4000}
        >
          <MediaProvider>
            {tracks?.map((track) => (
              <Track {...track} key={track.src} />
            ))}
          </MediaProvider>
          <DefaultVideoLayout
            // thumbnails="https://storage.googleapis.com/tsoai-resources-data/tirzepatide_thumbnails_1.vtt"
            // thumbnails="https://storage.googleapis.com/tsoai-resources-data/lowe_thumbnails_2.vtt"
            thumbnails={thumbnailsUrl}
            icons={defaultLayoutIcons}
            slots={{
              // playButton: <CustomPlayButton />,
              chaptersMenu: <div></div>,
            }}
            download
          />
        </MediaPlayer>
        {/* {chaptering} */}
        {/* <div ref={chaptersListRef}></div> */}
      </div>
      {/* <button
        onClick={() => {
          remote.pause();
        }}
      >
        pause
      </button>
      <button
        onClick={() => {
          remote.play();
        }}
      >
        play
      </button> */}

      <ChaptersPreviewBar
        thumbnailsUrl={thumbnailsUrl}
        chaptersUrl={tracks[0]?.src}
        seekAndPlay={seekAndPlay}
      />
    </div>
  );
};

export { VideoPlayer };
